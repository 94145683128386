import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["table"];

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    if (this.hasTableTarget) {
      $(this.tableTarget).DataTable({
        responsive: true,
        retrieve: true,
        autoWidth: false,
        processing: true,
        serverSide: false,
        paging: true,
        "pageLength": 25,
        deferRender: true,
        lengthMenu: [
          [10, 25, 50, 100],
          ['10 rows', '25 rows', '50 rows', '100 rows'],
        ],
        ajax: {
          url: '/camps/searchable_events_data',
          type: 'POST',
          dataType: 'json',
        },
        columns: [
         { data: 'Event', searchable: true, orderable: true },
         { data: 'Price Points', searchable: false, orderable: false },
         { data: 'Start Date', searchable: false, orderable: true },
         { data: 'End Date', searchable: false, orderable: true }
        ],
        language: {
          search: '<i class="fa fa-search"></i>',
          searchPlaceholder: "SEARCH",
          emptyTable: "No events available at the moment."
        },
      });
    }
  }
}
